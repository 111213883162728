import React from "react";

function Sectiontitle(props) {
  return (
    <div className="sectiontitle">
      <h2>{props.title}</h2>
    </div>
  );
}

export default Sectiontitle;
