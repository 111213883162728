import React, { useState, useEffect } from "react";
import axios from 'axios';
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";

function About(){
  const [information, setInformation] = useState("");

  useEffect(() =>{
    axios.get('/api/information')
      .then(response =>{
        setInformation(response.data)
      })
  }, [])

  return (
    <Layout>
      <div className="about-area section padding-top">
        <div className="container">
          <Sectiontitle title="About Me" />
          <div className="row">
            <div className="col-lg-4">
              <div className="about-image">
                <img
                  src={information.aboutImage}
                  alt="about"
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="about-content">
                <h3>
                  I am <span className="color-theme">{information.name}</span>
                </h3>
                <p>
                  I am a full-stack JavaScript developer experienced with all stages of the development cycle, from building the backend and setting up the server environment to creating a front-end. I have over 6 years of commercial experience working with Meteor, Node.js and React as well as creating microservices, public API and static websites.
                </p>
                <ul>
                  {!information.name ? null : <li>
                    <b>Full Name</b> {information.name}
                  </li>}
                  {!information.age ? null : <li>
                    <b>Age</b> {information.age} Years
                  </li>}
                  {!information.phone ? null : <li>
                    <b>Phone</b> {information.phone}
                  </li>}
                  {!information.nationality ? null : <li>
                    <b>Nationality</b> {information.nationality}
                  </li>}
                  {!information.language ? null : <li>
                    <b>Languages</b> {information.language}
                  </li>}
                  {!information.email ? null : <li>
                    <b>Email</b> {information.email}
                  </li>}
                  {!information.address ? null : <li>
                    <b>Address</b> {information.address}
                  </li>}
                  {!information.freelanceStatus ? null : <li>
                    <b>Freelance</b> {information.freelanceStatus}
                  </li>}
                </ul>
                {/*<a href={information.cvfile} className="button">Download CV</a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="service-area section padding-top">*/}
      {/*  <div className="container">*/}
      {/*    <Sectiontitle title="Services" />*/}
      {/*    <div className="service-wrapper">*/}
      {/*      <div className="row mt-30-reverse">*/}
      {/*        {services.map(service => (*/}
      {/*          <div className="col-lg-4 col-md-6 col-12 mt-30" key={service.title}>*/}
      {/*            <Service content={service}/>*/}
      {/*          </div>*/}
      {/*        ))}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="review-area section padding-top padding-bottom">*/}
      {/*  <div className="container">*/}
      {/*    <Sectiontitle title="Reviews" />*/}
      {/*    <div className="row justify-content-center">*/}
      {/*      <div className="col-12">*/}
      {/*        <Slider className="testimonial-slider" {...sliderSettings}>*/}
      {/*          {reviews.map(review =>(*/}
      {/*            <Testimonial key={review.id} content={review}/>*/}
      {/*          ))}*/}
      {/*        </Slider>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Layout>
  );
}

export default About;
